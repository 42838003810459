<template>
  <div class="about">
<!--    <template>-->
<!--      <v-file-input-->
<!--          v-model="files"-->
<!--          placeholder="Upload your documents"-->
<!--          label="File input"-->
<!--          multiple-->
<!--          prepend-icon="mdi-paperclip"-->
<!--      >-->
<!--        <template v-slot:selection="{ text }">-->
<!--          <v-chip-->
<!--              small-->
<!--              label-->
<!--              color="primary"-->
<!--          >-->
<!--            {{ text }}-->
<!--          </v-chip>-->
<!--        </template>-->
<!--      </v-file-input>-->
<!--      <v-btn-->
<!--          color="primary"-->
<!--          dark-->
<!--          @click="uploadFile"-->
<!--      >-->
<!--        Добавить-->
<!--      </v-btn>-->
<!--    </template>-->
  </div>
</template>

<script>
import Settings from '../settings/settings_url'
import {HTTP} from '@/settings/axios'

export default {
  data: () => ({
    files: [],
  }),
  methods: {
    uploadFile: function (){
      // const requestOptions = {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      //   params: {
      //     "file": this.files,
      //     "Email": "emailId"
      //   }
      // }
      let formData = new FormData();
      formData.append('file',  this.files[0]);
      formData.append('Email',"emailId");

      HTTP.post(Settings.API.URL_DEFAULT + "/health", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then(() => {

      }).catch(() => {

      });
    }
  },
}
</script>
